import React from 'react';
import sampleReport from './sampleReport';
import { Dna } from 'react-loader-spinner';

const InputForm = ({ report, setReport, handleSubmit, isLoading }) => (
  <div className="relative w-11/12 mt-12">
    <div className="relative w-full sm:w-9/12 md:w-3/4 lg:w-1/2 xl:w-3/5 2xl:w-1/2 mx-auto py-3">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <div className="flex justify-between mb-4">
            <h1 className="text-3xl font-medium mb-6 flex items-center">
              De-identification Playground
            </h1>
            <button
              type="button"
              className="btn btn-white py-1 px-6"
              onClick={() => setReport(sampleReport.report)}
            >
              Use sample data
            </button>
          </div>
          <label
            className="block text-gray-700 text-sm font-bold my-4"
            htmlFor="report"
          >
            We use NLP and language models to remove any PHI. This is a demo and you should not use
            this tool for production PHI removal.
            <br />
            If you're interested in De-Id as a service reach out to us at{' '}
            <a href="mailto:support@segmed.ai" className="link">
              support@segmed.ai
            </a>
            . No data is saved or stored by segmed.ai
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none h-72 overflow-y-auto"
            id="report"
            value={report}
            placeholder="Paste medical data and click clean the data to see our de-id in work"
            onChange={(e) => setReport(e.target.value)}
          />
        </div>
        <div class="flex justify-between">
          <button
            type="submit"
            className="btn btn-primary py-4 px-6"
            disabled={!report.length || isLoading}
          >
            {isLoading ? (
              <Dna
                visible={true}
                height="25"
                width="115"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            ) : (
              'Clean the data!'
            )}
          </button>
          <a
            className="btn btn-white py-2 px-6 ml-4 break-normal text-center"
            href="https://w35t0a4cudz.typeform.com/to/IFD8E3XY"
            target="_blank"
          >
            Have questions or feedback? <br />
            Contact us!
          </a>
        </div>
      </form>
    </div>
  </div>
);

export default InputForm;
