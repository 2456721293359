import React from 'react';
import { InfinitySpin } from 'react-loader-spinner';

const OutputDisplay = ({ title, isLoading, output, errorText }) => (
  <div className="w-full rounded text-gray-900 h-96 ">
    <h1 className="text-2xl my-3">{title}</h1>
    <div className="bg-white p-4 rounded text-gray-900 h-96 overflow-y-auto relative">
      {isLoading ? (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <InfinitySpin className="text-blue-500 w-10 h-10" />
        </div>
      ) : output ? (
        <div
          className="flex justify-center m-6 pr-4"
          dangerouslySetInnerHTML={{ __html: output }}
        />
      ) : (
        <p className="text-gray-400 italic">{errorText}</p>
      )}
    </div>
  </div>
);

export default OutputDisplay;
