import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const getSpacyNER = async (text) => {
  try {
    const response = await axios.post(`${serverUrl}/displacy`, {
      text,
    });
    return response.data;
  } catch {
    console.log('spaCy output broken');
    return { result: "Something's wrong with the spaCy Server." };
  }
};

export const callDeidReport = async (text) => {
  try {
    const response = await axios.post(`${serverUrl}/deidreport`, {
      text,
    });
    return response.data;
  } catch (error) {
    console.error('Error calling DeidReport API:', error);
    throw new Error('An error occurred while calling the DeidReport API.');
  }
};
