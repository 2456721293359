import React, { useState } from 'react';
import { useMutation } from 'react-query';
import './App.css';
import { callDeidReport, getSpacyNER } from './utils';
import OutputDisplay from './OutputDisplay';
import InputForm from './InputForm';
import segmedLogo from './assets/segmed-logo.png';

function App() {
  const [report, setReport] = useState('');
  const [output, setOutput] = useState('');
  const [displacyOutput, setDisplacyOutput] = useState('');

  const getSpacyNERMutation = useMutation(getSpacyNER, {
    onSuccess: (data) => {
      setDisplacyOutput(data.result);
    },
  });

  const deIdReportMutation = useMutation(callDeidReport, {
    onSuccess: (data) => {
      setOutput(data);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (report.split(' ').length < 1000) {
      try {
        await getSpacyNERMutation.mutateAsync(report);
        await deIdReportMutation.mutateAsync(report);
      } catch (error) {
        console.error('Error:', error);
        setOutput(error.message);
      }
    } else {
      alert(
        'Your report is too long. Please enter a report with less than 1500 words.',
      );
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 flex flex-col justify-center">
      <div className="fixed top-0 left-0 right-0 bg-white z-50 shadow-md flex justify-start py-4">
        <a href="https://segmed.ai">
          <img
            src={segmedLogo}
            alt="Segmed Logo"
            className="ml-32 h-10 w-auto hover:cursor-pointer"
          />
        </a>
      </div>

      <InputForm
        report={report}
        setReport={setReport}
        handleSubmit={handleSubmit}
        isLoading={
          deIdReportMutation.isLoading || getSpacyNERMutation.isLoading
        }
      />
      {/* Output and Displacy areas */}
      {displacyOutput && (
        <div className="flex flex-wrap sm:flex-nowrap justify-around mt-12 sm:space-x-4 sm:space-y-0 space-y-4">
          <OutputDisplay
            title="Before De-identification:"
            isLoading={getSpacyNERMutation.isLoading}
            output={displacyOutput}
            errorText="Something went wrong while parsing the report with Spacy NER"
          />
          <OutputDisplay
            title="After De-identification:"
            isLoading={deIdReportMutation.isLoading}
            output={output}
            errorText="Something went wrong while calling the OpenAI API"
          />
        </div>
      )}
    </div>
  );
}

export default App;
